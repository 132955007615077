* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

/* Responsive font sizes */
html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

/* Improve button and input tap targets on mobile */
button, 
input[type="submit"],
input[type="button"] {
  min-height: 44px;
  min-width: 44px;
}

/* Improve link tap targets on mobile */
a {
  padding: 8px 0;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Better form elements on mobile */
input,
select,
textarea {
  font-size: 16px; /* Prevents zoom on focus in iOS */
}
